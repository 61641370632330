import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import trainIcon from "../assets/train.png";
import StationPanel from "./StationPanel";
import MapStyle from "../mapStyle.js";
import { calculateDistanceFixed } from "../utils/routeUtils.js";
import { fetchMetroLines } from "../firebaseHelpers";

// Harita stil ayarları
const mapContainerStyle = {
  width: "100%",
  height: "94vh",
  borderRadius: "15px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
};

// İstanbul merkez konumu
const center = { lat: 41.02937035599793, lng: 29.030050422002546 };

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

// Polyline seçenekleri
const polylineOptions = (color, isSelected) => ({
  strokeColor: isSelected ? `rgba(${hexToRgb(color)}, 1)` : color,
  strokeOpacity: isSelected ? 1 : 0.8,
  strokeWeight: isSelected ? 10 : 8,
  geodesic: true,
  zIndex: isSelected ? 10 : 5,
  icons: isSelected
    ? [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 0.8,
            strokeWeight: 22,
            strokeColor: `rgba(${hexToRgb(color)}, 0.3)`,
          },
          offset: "0",
          repeat: "10px",
        },
      ]
    : [],
});

const routePolylineOptions = {
  strokeColor: "rgba(255, 165, 0, 0.8)", // Altın rengi
  strokeWeight: 8, // Çizgi kalınlığı
  strokeOpacity: 1, // Çizgi opaklığı
  zIndex: 30, // Çizgi önceliği
  icons: [
    {
      icon: {
        path: "M 0 0 m -2, 0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0", // Çember SVG path
        fillOpacity: 1,
        fillColor: "rgba(255, 215, 0, 0.9)", // Çemberin dolgu rengi
        strokeOpacity: 1,
        strokeColor: "rgba(255, 165, 0, 1)", // Çember kenar rengi
        scale: 2, // Çember büyüklüğü
      },
      offset: "0", // İlk sembolün başlangıç noktası
      repeat: "20px", // Semboller arasındaki mesafe
    },
  ],
};

const restrictedBounds = {
  north: 41.5,
  south: 40.5,
  east: 29.9,
  west: 27.8,
};

export default function Map({
  selectedLine,
  setSelectedLine,
  selectedStation,
  setSelectedStation,
  placeA,
  placeB,
  route,
  routeDetails,
  setRouteDetails,
  setPlaceA,
  setPlaceB,
  tariffDetails,
  setTariffDetails,
  routePlaceA,
  setRoutePlaceA,
  routePlaceB,
  setRoutePlaceB,
  mapRef,
  trafficLayerVisible,
  setTrafficLayerVisible,
  trafficLayerRef,
  duration,
  travelMode,
}) {
  useEffect(() => {
    // trafficLayerVisible değiştiğinde trafficLayer'ı güncelle
    if (window.google && window.google.maps && mapRef.current) {
      if (!trafficLayerRef.current) {
        // TrafficLayer henüz oluşturulmadıysa oluştur
        trafficLayerRef.current = new window.google.maps.TrafficLayer();
      }
      // Görünürlük durumuna göre haritaya ekle veya çıkar
      trafficLayerRef.current.setMap(
        trafficLayerVisible ? mapRef.current : null
      );
    }
  }, [trafficLayerVisible, mapRef, trafficLayerRef]);

  const [routePolyline, setRoutePolyline] = useState(null);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [mapZoom, setMapZoom] = useState(6);
  const [trainPosition, setTrainPosition] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isPanelLoading, setIsPanelLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [metroLines, setmetroLines] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchMetroLines();
      setmetroLines(data);
    };
    loadData();
  }, []);

  const dashedLineOptions = {
    strokeOpacity: 0, // Ana çizgi görünmez yapılır
    strokeWeight: 4, // Çizgi kalınlığı
    icons: [
      {
        icon: {
          path: "M 0,-1 0,1", // Kesikli çizgi sembolü
          strokeOpacity: 1,
          scale: 4,
        },
        offset: "0",
        repeat: "20px", // Kesikler arasındaki boşluk
      },
    ],
  };

  // Başlangıç noktasından en yakın metro durağına çizgi
  const startLine =
    placeA?.geometry?.location && route.length > 0
      ? [
          {
            lat: placeA.geometry.location.lat(),
            lng: placeA.geometry.location.lng(),
          },
          { lat: route[0].location.lat, lng: route[0].location.lng },
        ]
      : null;

  // Bitiş noktasından en yakın metro durağına çizgi
  const endLine =
    placeB?.geometry?.location && route.length > 0
      ? [
          {
            lat: placeB.geometry.location.lat(),
            lng: placeB.geometry.location.lng(),
          },
          {
            lat: route[route.length - 1].location.lat,
            lng: route[route.length - 1].location.lng,
          },
        ]
      : null;

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setMapZoom(mapRef.current.getZoom());
    }
  };

  const animateTrainOnLine = (line) => {
    debugger;
    const path = line.stations.map((station) => station.location);
    let currentIndex = 0;
    let t = 0;

    const moveTrain = () => {
      const start = path[currentIndex];
      const end = path[currentIndex + 1];

      if (!start || !end) return;

      t += 0.04;

      if (t >= 1) {
        t = 0;
        currentIndex++;

        if (currentIndex >= path.length - 1) {
          path.reverse();
          currentIndex = 0;
        }

        return;
      }

      const newLat = start.lat + t * (end.lat - start.lat);
      const newLng = start.lng + t * (end.lng - start.lng);

      setTrainPosition({ lat: newLat, lng: newLng });
    };

    const interval = setInterval(moveTrain, 50);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (selectedLine) {
      setTrainPosition(selectedLine.stations[0].location);
      const clearAnimation = animateTrainOnLine(selectedLine);
      return () => clearAnimation();
    }
  }, [selectedLine]);

  const handleLineSelect = (lineId) => {
    console.log(JSON.stringify(metroLines));
    const line = metroLines.find((line) => line.id === Number(lineId));
    setSelectedLine(line);

    if (line && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      line.stations.forEach((station) => bounds.extend(station.location));
      mapRef.current.fitBounds(bounds);
      setSelectedStation(null);
      setNearbyPlaces([]);
      setSelectedCategories([]);
      setNearbyPlaces([]);
    }
  };

  const resetMap = () => {
    debugger;
    mapRef.current.panTo(center);
    mapRef.current.setZoom(6);
    setSelectedStation(null);
    setNearbyPlaces([]);
    setSelectedLine(null);
    setTrainPosition(null);
    setSelectedCategories([]);
    setNearbyPlaces([]);
    setRoutePolyline(null);
    setRouteDetails([]);
    setPlaceA(null);
    setPlaceB(null);
    setRoutePlaceA([]);
    setRoutePlaceB([]);
    setTariffDetails([]);
  };

  const findLinesForStation = (station) => {
    return metroLines
      .filter((line) =>
        line.stations.some((lineStation) => lineStation.name === station.name)
      )
      .map((line) => line.name);
  };

  useEffect(() => {
    if (placeA && placeB) {
      setRoutePolyline(route);
      if (mapRef.current && route.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        route.forEach((station) => bounds.extend(station.location));
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [placeA, placeB, route, mapRef]);

  const fetchNearbyPlaces = async (location, categories) => {
    if (categories.length === 0) {
      setNearbyPlaces([]);
      return;
    }

    const service = new window.google.maps.places.PlacesService(mapRef.current);

    try {
      setIsPanelLoading(true);
      const allResults = await Promise.all(
        categories.map(async (type) => {
          const request = {
            location,
            radius: 1000,
            type,
          };

          return new Promise((resolve, reject) => {
            service.nearbySearch(request, (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                resolve(results);
              } else {
                resolve([]);
              }
            });
          });
        })
      );

      const mergedResults = allResults.flat();
      const uniqueResults = Array.from(
        new Set(mergedResults.map((place) => place.place_id))
      ).map((id) => mergedResults.find((place) => place.place_id === id));

      const updatedResults = uniqueResults.map((place) => ({
        ...place,
        distance: calculateDistanceFixed(
          location.lat,
          location.lng,
          place.geometry.location.lat(),
          place.geometry.location.lng()
        ),
      }));

      updatedResults.sort((a, b) => a.distance - b.distance);

      setNearbyPlaces(updatedResults);
      setIsPanelLoading(false);
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setNearbyPlaces([]);
      setIsPanelLoading(false);
    }
  };

  const walkingPolylineOptionsA = {
    strokeColor: "rgba(0, 123, 255, 1)", // Ana çizgi için belirgin mavi
    strokeWeight: 5, // Çizgi kalınlığı
    strokeOpacity: 1, // Çizgi opaklığı
    zIndex: 40, // Çizgi önceliği
    icons: [
      {
        icon: {
          path: "M 0,-1 0,1", // Kesikli çizgi sembolü
          strokeOpacity: 0.8,
          strokeWeight: 2,
          scale: 4,
          strokeColor: "rgba(0, 123, 255, 0.5)", // Daha açık ton
        },
        offset: "0", // Kesikli çizginin başlangıç noktası
        repeat: "20px", // Çizgiler arasındaki mesafe
      },
      {
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE, // Ok sembolü
          scale: 4,
          fillColor: "rgba(255, 69, 0, 1)", // Kırmızı tonlu ok
          fillOpacity: 1,
          strokeColor: "rgba(255, 69, 0, 1)", // Kırmızı ok kenarları
          strokeWeight: 2,
        },
        offset: "20px", // İlk sembolün başlangıç noktası
        repeat: "60px", // Ok sembolleri arasındaki mesafe
      },
    ],
  };

  const walkingPolylineOptionsB = {
    strokeColor: "rgba(0, 123, 255, 1)", // Ana çizgi için belirgin mavi
    strokeWeight: 5, // Çizgi kalınlığı
    strokeOpacity: 1, // Çizgi opaklığı
    zIndex: 40, // Çizgi önceliği
    icons: [
      {
        icon: {
          path: "M 0,-1 0,1", // Kesikli çizgi sembolü
          strokeOpacity: 0.8,
          strokeWeight: 2,
          scale: 4,
          strokeColor: "rgba(0, 123, 255, 0.5)", // Daha açık ton
        },
        offset: "0", // Kesikli çizginin başlangıç noktası
        repeat: "20px", // Çizgiler arasındaki mesafe
      },
      {
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE, // Ok sembolü
          scale: 4,
          fillColor: "rgba(255, 69, 0, 1)", // Kırmızı tonlu ok
          fillOpacity: 1,
          strokeColor: "rgba(255, 69, 0, 1)", // Kırmızı ok kenarları
          strokeWeight: 2,
        },
        offset: "20px", // İlk sembolün başlangıç noktası
        repeat: "60px", // Ok sembolleri arasındaki mesafe
      },
    ],
  };

  return (
    <div style={{ position: "relative" }}>
      <StationPanel
        selectedStation={selectedStation}
        findLinesForStation={findLinesForStation}
        fetchNearbyPlaces={fetchNearbyPlaces}
        nearbyPlaces={nearbyPlaces}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        isPanelLoading={isPanelLoading}
      />

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={6}
        center={center}
        // options={{
        //   styles: MapStyle,
        //   mapTypeControl: false,
        //   fullscreenControl: false,
        //   restriction: {
        //     latLngBounds: restrictedBounds,
        //     strictBounds: true,
        //   },
        // }}
        options={{
          styles: MapStyle,
          mapTypeControl: false,
          fullscreenControl: false,
          restriction: {
            latLngBounds: restrictedBounds,
            strictBounds: true,
          },
        }}
        onLoad={(map) => (mapRef.current = map)}
        onZoomChanged={handleZoomChanged}
      >
        {metroLines
          .filter((line) => {
            // Eğer route doluysa, sadece route içindeki duraklara sahip hatları dahil et
            if (routePolyline) {
              const routeStationNames = routePolyline.map(
                (station) => station.name
              ); // Route içindeki durak isimlerini alın
              return (
                line.railwayType !== "Marmaray" &&
                line.stations.some((station) =>
                  routeStationNames.includes(station.name)
                )
              );
            }
            // Route boşsa tüm Marmaray olmayan hatları dahil et
            return line.railwayType !== "Marmaray";
          })
          .map((line) => (
            <React.Fragment key={line.id}>
              {line.stations
                .filter((station) => {
                  // Sadece route içinde olan durakları filtrele
                  if (routePolyline) {
                    const routeStationNames = routePolyline.map(
                      (station) => station.name
                    );
                    return routeStationNames.includes(station.name);
                  }
                  return true; // Route boşsa tüm durakları göster
                })
                .map((station) => (
                  <Marker
                    key={station.id}
                    position={station.location}
                    title={station.name}
                    onClick={() => {
                      setSelectedStation(station);
                      mapRef.current.panTo(station.location);
                      mapRef.current.setZoom(15);
                      setSelectedCategories([]);
                      setNearbyPlaces([]);
                    }}
                    label={
                      mapZoom > 12 || selectedLine || routePolyline
                        ? {
                            text: station.name,
                            fontSize: "12px",
                            fontWeight: "500",
                            color:
                              selectedStation?.id === station.id
                                ? "#FF0000"
                                : "#333",
                          }
                        : null
                    }
                    icon={{
                      url:
                        selectedStation?.id === station.id
                          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                      scaledSize:
                        selectedStation?.id === station.id
                          ? new window.google.maps.Size(30, 30)
                          : new window.google.maps.Size(20, 20),
                      labelOrigin: new window.google.maps.Point(10, -10),
                    }}
                    animation={
                      selectedStation?.id === station.id
                        ? window.google.maps.Animation.BOUNCE
                        : null
                    }
                  />
                ))}

              {!routePolyline && (
                <Polyline
                  path={line.stations.map((station) => station.location)}
                  options={polylineOptions(
                    line.color,
                    line.id === selectedLine?.id
                  )}
                />
              )}
            </React.Fragment>
          ))}
        {/*Kesikli Çizgiler */}
        {routePlaceA && (
          <Polyline
            path={routePlaceA} // Polyline rotası
            options={walkingPolylineOptionsA}
          />
        )}
        {routePlaceB && (
          <Polyline
            path={routePlaceB} // Polyline rotası
            options={walkingPolylineOptionsB}
          />
        )}

        {startLine && !routePlaceA && (
          <Polyline path={startLine} options={dashedLineOptions} />
        )}
        {endLine && !routePlaceB && (
          <Polyline path={endLine} options={dashedLineOptions} />
        )}

        {/* Başlangıç ve Bitiş Noktaları Arasındaki Çizgi */}
        {routePolyline && (
          <Polyline
            path={routePolyline.map((station) => {
              if (!station || !station.location) {
                console.error("Eksik veya geçersiz istasyon:", station);
                return null; // Geçersiz istasyonu atla
              }
              return station.location;
            })}
            options={routePolylineOptions}
          />
        )}
        {/* Başlangıç Noktası Marker */}
        {placeA?.geometry?.location && (
          <Marker
            position={{
              lat: placeA.geometry.location.lat(),
              lng: placeA.geometry.location.lng(),
            }}
            label={{
              text: placeA?.name,
              color: "#da6161",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Başlangıç için yeşil pin
              labelOrigin: new window.google.maps.Point(10, -10),
            }}
          />
        )}
        {/* Bitiş Noktası Marker */}
        {placeB?.geometry?.location && (
          <Marker
            position={{
              lat: placeB.geometry.location.lat(),
              lng: placeB.geometry.location.lng(),
            }}
            label={{
              text: placeB?.name,
              color: "#da6161",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Bitiş için kırmızı pin
              labelOrigin: new window.google.maps.Point(10, -10),
            }}
          />
        )}
        {/* Nearby Places Marker'ları */}
        {nearbyPlaces.map((place, index) => (
          <Marker
            key={`${index}-${mapZoom}`} // Zoom seviyesi değiştiğinde yeniden render
            position={place.geometry.location}
            title={place.name}
            label={
              mapZoom > 15 // Zoom seviyesi 14'ten büyükse etiket görünür
                ? {
                    text: place.name,
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#333",
                  }
                : null // Daha düşük zoom seviyelerinde etiket gizlenir
            }
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
              scaledSize: new window.google.maps.Size(20, 20),
              labelOrigin: new window.google.maps.Point(10, -10),
            }}
          />
        ))}
        {trainPosition && trainPosition.lat && trainPosition.lng && (
          <Marker
            position={trainPosition}
            icon={{
              url: trainIcon,
              scaledSize: new window.google.maps.Size(40, 40),
              zIndex: 10,
            }}
            title="Tren"
          />
        )}
        {routeDetails.length > 0 && (
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              width: "350px",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              borderRadius: "15px",
              boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
              zIndex: 10,
              overflow: "hidden",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 20px",
                backgroundColor: "#f8f9fa",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
              }}
              onClick={() => setModalOpen(!modalOpen)}
            >
              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                🚉 Güzergah Detayları
              </span>
              <span style={{ fontSize: "18px", color: "#666" }}>
                {modalOpen ? "⬇️" : "⬆️"}
              </span>
            </div>

            <div
              style={{
                maxHeight: modalOpen ? "400px" : "0",
                overflowY: "auto",
                padding: modalOpen ? "15px 20px" : "0",
                transition: "max-height 0.4s ease, padding 0.4s ease",
              }}
            >
              {modalOpen && (
                <>
                  {/* Tarife Detayları */}
                  <div
                    style={{
                      backgroundColor: "#e9f5e9",
                      padding: "15px",
                      marginBottom: "15px",
                      borderRadius: "10px",
                      border: "1px solid #d4edda",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      textAlign: "left",
                      color: "#155724",
                    }}
                  >
                    <p style={{ margin: 0, fontSize: "14px" }}>
                      <strong>💰 Tarifeler:</strong>
                      <br />
                      <span>🔹 Tam: {tariffDetails?.fullFare || "N/A"} ₺</span>
                      <br />
                      <span>
                        🔹 Öğrenci: {tariffDetails?.studentFare || "N/A"} ₺
                      </span>
                      <br />
                      <span>
                        🔹 Sosyal: {tariffDetails?.socialFare || "N/A"} ₺
                      </span>
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "15px",
                      display: "grid",
                      gridTemplateColumns: "auto auto 28px",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <strong>📍 A Noktası:</strong>
                    <span>{placeA?.name || "Belirtilmedi"}</span>
                    {placeA && route[0] && (
                      <a
                        href={`https://www.google.com/maps/dir/?api=1&origin=${placeA.geometry.location.lat()},${placeA.geometry.location.lng()}&destination=${
                          route[0].location.lat
                        },${route[0].location.lng}&travelmode=${
                          travelMode === "walking" ? "walking" : "driving"
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          textDecoration: "none",
                          borderRadius: "50%",
                          fontSize: "16px",
                          width: "28px",
                          height: "28px",
                          lineHeight: "28px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#0056b3")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "#007bff")
                        }
                        title="Google Maps ile yol tarifi"
                      >
                        <i className="fas fa-directions" />
                      </a>
                    )}
                    <strong>📍 B Noktası:</strong>
                    <span>{placeB?.name || "Belirtilmedi"}</span>
                    {placeB && route[route.length - 1] && (
                      <a
                        href={`https://www.google.com/maps/dir/?api=1&origin=${placeB.geometry.location.lat()},${placeB.geometry.location.lng()}&destination=${
                          route[route.length - 1].location.lat
                        },${route[route.length - 1].location.lng}&travelmode=${
                          travelMode === "walking" ? "walking" : "driving"
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#28a745",
                          color: "#fff",
                          textDecoration: "none",
                          borderRadius: "50%",
                          fontSize: "16px",
                          width: "28px",
                          height: "28px",
                          lineHeight: "28px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#1e7e34")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "#28a745")
                        }
                        title="Google Maps ile yol tarifi"
                      >
                        <i className="fas fa-directions" />
                      </a>
                    )}
                  </p>
                  <span
                    style={{
                      display: "inline-block",
                      marginTop: "10px",
                      padding: "6px",
                      backgroundColor: "#fff3cd",
                      borderRadius: "10px",
                      border: "1px solid #ffeeba",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      color: "#856404",
                      fontWeight: "bold",
                      position: "relative",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                    className="durationSpan"
                    onMouseEnter={(e) => {
                      const tooltip = e.currentTarget.querySelector(".tooltip");
                      tooltip.style.visibility = "visible";
                      tooltip.style.display = "inline-block";
                      tooltip.style.opacity = 1;
                    }}
                    onMouseLeave={(e) => {
                      const tooltip = e.currentTarget.querySelector(".tooltip");
                      tooltip.style.visibility = "hidden";
                      tooltip.style.opacity = 0;
                    }}
                  >
                    {travelMode === "walking" && "🚶"}
                    {travelMode === "driving" && "🚗"}
                    {travelMode === "default" && "🦅"} Tahmini Süre:{" "}
                    {duration || "Hesaplanıyor..."} dakika
                    <div
                      style={{
                        visibility: "hidden",
                        opacity: 0,
                        position: "absolute",
                        top: "100%",
                        transform: "translateX(-56%)",
                        backgroundColor: "#343a40",
                        color: "#fff",
                        padding: "8px",
                        borderRadius: "8px",
                        fontSize: "12px",
                        lineHeight: "1.6",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                        zIndex: 1000,
                        width: "280px",
                        textAlign: "center",
                        transition: "visibility 0.2s, opacity 0.2s",
                        border: "1px solid #555",
                      }}
                      className="tooltip"
                    >
                      <span
                        style={{
                          display: "block",
                          marginBottom: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        💡 Bilgilendirme
                      </span>
                      Bu süre, yalnızca metro hatlarındaki güzergâh için
                      ortalama süreyi ifade eder. A ve B noktalarından en yakın
                      metro istasyonlarına ulaşım sürelerini kapsamaz.
                    </div>
                  </span>
                  <ul style={{ padding: 0, listStyleType: "none" }}>
                    {routeDetails.map((detail, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "15px",
                          padding: "10px",
                          backgroundColor: "#f1f8ff",
                          borderRadius: "8px",
                          border: "1px solid #cce5ff",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          color: "#004085",
                        }}
                      >
                        <strong>🚇 {detail.lineName}:</strong>
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          {detail.start} → {detail.end}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </GoogleMap>

      <div
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          zIndex: 2,
          display: "flex",
          gap: "10px",
        }}
      >
        <select
          onChange={(e) => handleLineSelect(e.target.value)}
          style={{
            padding: "5px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            background: "white",
            fontSize: "15px",
            maxWidth: "280px", // Select genişliği
          }}
          value={selectedLine ? selectedLine.id : ""}
        >
          <option value="">Hat Seçiniz</option>
          {metroLines.map((line) => (
            <option
              key={line.id}
              value={line.id}
              title={line.name} // Uzun metinler için tooltip
            >
              {line.name.length > 26
                ? `${line.name.substring(0, 24)}..` // Uzun metinleri kes
                : line.name}
            </option>
          ))}
        </select>

        <button
          onClick={resetMap}
          style={{
            padding: "10px 20px",
            backgroundColor: "#e72419",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          Reset
        </button>
        <button
          onClick={() => setTrafficLayerVisible(!trafficLayerVisible)}
          style={{
            padding: "10px 20px",
            backgroundColor: trafficLayerVisible ? "#28a745" : "#6c757d", // Aktif: Yeşil, Pasif: Gri
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
            animation: trafficLayerVisible
              ? "trafficBlink 1s infinite"
              : "none", // Animasyon sadece aktifken
            transition: "background-color 0.3s ease", // Pasif durum geçişi
          }}
        >
          {trafficLayerVisible ? "Traffic On" : "Traffic Off"}
        </button>
      </div>
    </div>
  );
}
