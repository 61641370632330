export const calculateRoute = (startStation, endStation, metroLines) => {
  debugger;
  const graph = buildGraph(metroLines, startStation, endStation);

  const path = dijkstra(graph, startStation.name, endStation.name);

  if (!path || path.length === 0) {
    console.log("Route not found!");
    return;
  }

  const details = buildRouteDetails(path, metroLines);
  const tariffs = calculateTariffs(path, metroLines);
  const duration = calculateAverageDuration(path, metroLines);

  const route = path.map((stationName) => {
    const station = metroLines
      .flatMap((line) => line.stations)
      .find((station) => station.name === stationName);

    const line = metroLines.find((line) =>
      line.stations.some((s) => s.name === stationName)
    );

    return {
      ...station,
      line: line ? { id: line.id, name: line.name, color: line.color } : null,
    };
  });

  return { route, details, tariffs, duration };
};

// Tarife hesaplama fonksiyonu
const calculateTariffs = (path, metroLines) => {
  const fares = {
    fullFare: 20.0, // İlk biniş
    studentFare: 9.76,
    socialFare: 14.32,
    transfer: {
      fullFare: [14.32, 10.87, 6.87, 6.87, 6.87], // Aktarma ücretleri
      studentFare: [4.28, 4.02, 3.44, 3.44, 3.44],
      socialFare: [8.59, 6.57, 4.28, 4.28, 4.28],
    },
  };

  let fullFareTotal = fares.fullFare;
  let studentFareTotal = fares.studentFare;
  let socialFareTotal = fares.socialFare;
  let transferCount = 0;

  for (let i = 0; i < path.length - 1; i++) {
    const currentStationName = path[i];
    const nextStationName = path[i + 1];

    const lineOfCurrent = metroLines.find((line) =>
      line.stations.some((station) => station.name === currentStationName)
    );
    const lineOfNext = metroLines.find((line) =>
      line.stations.some((station) => station.name === nextStationName)
    );

    if (lineOfCurrent && lineOfNext && lineOfCurrent.name !== lineOfNext.name) {
      // Farklı hatta geçiş
      fullFareTotal +=
        fares.transfer.fullFare[transferCount] ||
        fares.transfer.fullFare.slice(-1)[0];
      studentFareTotal +=
        fares.transfer.studentFare[transferCount] ||
        fares.transfer.studentFare.slice(-1)[0];
      socialFareTotal +=
        fares.transfer.socialFare[transferCount] ||
        fares.transfer.socialFare.slice(-1)[0];
      transferCount++;
    }
  }

  return {
    fullFare: fullFareTotal.toFixed(2),
    studentFare: studentFareTotal.toFixed(2),
    socialFare: socialFareTotal.toFixed(2),
  };
};

const calculateAverageDuration = (path, metroLines) => {
  if (!Array.isArray(path) || path.length < 2) {
    return "Invalid path";
  }

  let totalDuration = 0;

  for (let i = 0; i < path.length - 1; i++) {
    const currentStationName = path[i];
    const nextStationName = path[i + 1];

    const line = metroLines.find(
      (line) =>
        line.stations.some((station) => station.name === currentStationName) &&
        line.stations.some((station) => station.name === nextStationName)
    );

    if (line) {
      const stopsCount = line.stations.length;
      const durationPerStop = line.duration / stopsCount;

      totalDuration += durationPerStop;
    }
  }

  return Math.round(totalDuration); // Toplam süre (dakika) olarak döndürülür
};

function buildGraph(lines) {
  const graph = {};

  lines.forEach((line) => {
    line.stations.forEach((station, index) => {
      if (!graph[station.name]) {
        graph[station.name] = {};
      }

      // Önceki istasyon bağlantısı
      if (index > 0) {
        const prevStation = line.stations[index - 1];
        graph[station.name][prevStation.name] = calculateDistance(
          station.location.lat,
          station.location.lng,
          prevStation.location.lat,
          prevStation.location.lng
        );
      }

      // Sonraki istasyon bağlantısı
      if (index < line.stations.length - 1) {
        const nextStation = line.stations[index + 1];
        graph[station.name][nextStation.name] = calculateDistance(
          station.location.lat,
          station.location.lng,
          nextStation.location.lat,
          nextStation.location.lng
        );
      }
    });
  });

  return graph;
}

// Dijkstra algoritması
function dijkstra(graph, start, end) {
  const distances = {};
  const previous = {};
  const queue = new Set(Object.keys(graph));

  // Başlangıç noktaları başlatılıyor
  Object.keys(graph).forEach((node) => {
    distances[node] = Infinity;
    previous[node] = null;
  });
  distances[start] = 0;

  while (queue.size > 0) {
    const current = Array.from(queue).reduce((minNode, node) =>
      distances[node] < distances[minNode] ? node : minNode
    );

    if (current === end) break;

    queue.delete(current);

    Object.entries(graph[current]).forEach(([neighbor, weight]) => {
      const alt = distances[current] + weight;
      if (alt < distances[neighbor]) {
        distances[neighbor] = alt;
        previous[neighbor] = current;
      }
    });
  }

  // Path oluşturuluyor
  const path = [];
  let currentNode = end;

  while (currentNode) {
    path.unshift(currentNode);
    currentNode = previous[currentNode];
  }

  return path.length ? path : null; // Eğer path boşsa null döndür
}

function buildRouteDetails(path, metroLines) {
  if (!Array.isArray(path) || path.length < 2) {
    return [];
  }

  const details = [];
  let currentLine = null;
  let segmentStart = path[0];

  for (let i = 1; i < path.length; i++) {
    const localSegmentStart = segmentStart; // Lokal kopya
    const currentStationName = path[i - 1];
    const nextStationName = path[i];

    const currentStation = metroLines
      .flatMap((line) => line.stations)
      .find((station) => station.name === currentStationName);
    const nextStation = metroLines
      .flatMap((line) => line.stations)
      .find((station) => station.name === nextStationName);

    if (!currentStation || !nextStation) continue;

    const line = metroLines.find(
      (line) =>
        line.stations.some((station) => station.name === currentStation.name) &&
        line.stations.some((station) => station.name === nextStation.name)
    );

    if (!line) {
      details.push({
        type: "dash",
        start: localSegmentStart,
        end: nextStation.name,
        startLocation: currentStation.location,
        endLocation: nextStation.location,
      });
      segmentStart = nextStation.name;
      currentLine = null;
      continue;
    }

    if (currentLine === line.name) {
      continue;
    }

    if (currentLine) {
      details.push({
        type: "line",
        lineName: currentLine,
        start: localSegmentStart,
        end: currentStation.name,
        startLocation: metroLines
          .flatMap((line) => line.stations)
          .find((station) => station.name === localSegmentStart)?.location,
        endLocation: currentStation.location,
      });
    }

    currentLine = line.name;
    segmentStart = currentStation.name;
  }

  // Son segmenti ekle
  const finalStation = path[path.length - 1];
  const finalStationObject = metroLines
    .flatMap((line) => line.stations)
    .find((station) => station.name === finalStation);

  if (currentLine && finalStationObject) {
    details.push({
      type: "line",
      lineName: currentLine,
      start: segmentStart,
      end: finalStation,
      startLocation: metroLines
        .flatMap((line) => line.stations)
        .find((station) => station.name === segmentStart)?.location,
      endLocation: finalStationObject.location,
    });
  }

  return details;
}

export const calculateDistance = (lat1, lng1, lat2, lng2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Dünya'nın yarıçapı (km)
  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Mesafe (km)
};

export const calculateDistanceFixed = (lat1, lng1, lat2, lng2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371;
  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c).toFixed(2);
};
