import React, { useState, useEffect, useRef, useCallback } from "react";
import { calculateDistance, calculateRoute } from "../utils/routeUtils.js";
import { istanbulBounds } from "../constants";
import { fetchMetroLines } from "../firebaseHelpers";

export default function PlaceSearchModal({
  onClose,
  setPlaceA,
  setPlaceB,
  setRoute,
  setRouteDetails,
  setTariffDetails,
  setRoutePlaceA,
  setRoutePlaceB,
  changeTrafficLayer,
  setDuration,
  travelMode,
  setTravelMode,
}) {
  const [nearestStationsA, setNearestStationsA] = useState([]);
  const [nearestStationsB, setNearestStationsB] = useState([]);
  const [, setSearchStationA] = useState("");
  const [, setSearchStationB] = useState("");

  const inputARef = useRef(null);
  const inputBRef = useRef(null);
  const autocompleteARef = useRef(null);
  const autocompleteBRef = useRef(null);

  const findNearestStations = useCallback(
    async (place, setStations) => {
      const metroLines = await fetchMetroLines();

      if (place && place.geometry) {
        const { lat, lng } = place.geometry.location;
        if (typeof lat !== "function" || typeof lng !== "function") {
          console.error("Geçersiz yer bilgisi: latitude veya longitude eksik.");
          return;
        }

        const location = { lat: lat(), lng: lng() };

        const allStations = metroLines.flatMap((line) =>
          line.stations.map((station) => ({ ...station, line }))
        );

        const stationsWithDistance = allStations.map((station) => {
          if (
            typeof station.location.lat !== "number" ||
            typeof station.location.lng !== "number"
          ) {
            console.error(
              "Geçersiz istasyon bilgisi: latitude veya longitude eksik."
            );
            return { ...station, distance: Infinity };
          }

          return {
            ...station,
            distance: calculateDistance(
              location.lat,
              location.lng,
              station.location.lat,
              station.location.lng
            ),
          };
        });

        const validStations = stationsWithDistance.filter(
          (station) => station.distance !== Infinity
        );
        validStations.sort((a, b) => a.distance - b.distance);
        const closestStations = validStations.slice(0, 3);

        setStations(closestStations);
        setRoutePlaceA(null);
        setRoutePlaceB(null);
      }
    },
    [setRoutePlaceA, setRoutePlaceB]
  );

  const findNearestStationsByWalking = useCallback(
    async (place, setStations, whichPlace) => {
      const metroLines = await fetchMetroLines();

      if (!place || !place.geometry) return;

      const { lat, lng } = place.geometry.location;
      if (typeof lat !== "function" || typeof lng !== "function") {
        console.error("Geçersiz yer bilgisi: latitude veya longitude eksik.");
        return;
      }

      const location = { lat: lat(), lng: lng() };

      // Tüm istasyonları topla ve mesafeyi hesapla
      const allStations = metroLines.flatMap((line) =>
        line.stations.map((station) => ({
          ...station,
          line,
          distance: calculateDistance(
            location.lat,
            location.lng,
            station.location.lat,
            station.location.lng
          ),
        }))
      );

      // İlk iki en yakın istasyonu bul
      const validStations = allStations.filter(
        (station) => station.distance !== Infinity
      );
      validStations.sort((a, b) => a.distance - b.distance);
      const closestStations = validStations.slice(0, 2);

      // Sadece bu iki istasyon için Distance Matrix API kullan
      const service = new window.google.maps.DistanceMatrixService();
      const chosenTravelMode = window.google.maps.TravelMode.WALKING;

      try {
        const response = await new Promise((resolve, reject) => {
          service.getDistanceMatrix(
            {
              origins: [
                new window.google.maps.LatLng(location.lat, location.lng),
              ],
              destinations: closestStations.map(
                (station) =>
                  new window.google.maps.LatLng(
                    station.location.lat,
                    station.location.lng
                  )
              ),
              travelMode: chosenTravelMode,
            },
            (response, status) => {
              if (status === window.google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            }
          );
        });

        const distances = response.rows[0].elements;

        // Mesafeleri ve istasyonları güncelle
        const stationsWithWalkingDistance = closestStations.map(
          (station, index) => ({
            ...station,
            walkingDistance: distances[index].distance?.value || Infinity,
          })
        );

        // İstasyonları yürüme mesafesine göre sırala
        stationsWithWalkingDistance.sort(
          (a, b) => a.walkingDistance - b.walkingDistance
        );

        // Sadece iki istasyon için bu işlem yapıldığından, hepsini setStations'e ekleyebiliriz
        setStations(stationsWithWalkingDistance);

        // Geri kalan kodunuz buraya gelebilir, örneğin rota çizimi
        if (stationsWithWalkingDistance.length > 0) {
          let origin = stationsWithWalkingDistance[0].location;
          let destination = location;
          if (whichPlace !== "A") {
            origin = location;
            destination = stationsWithWalkingDistance[0].location;
          }

          const directionsService = new window.google.maps.DirectionsService();
          const routeRequest = {
            origin: new window.google.maps.LatLng(origin.lat, origin.lng),
            destination: new window.google.maps.LatLng(
              destination.lat,
              destination.lng
            ),
            travelMode: chosenTravelMode,
          };

          directionsService.route(routeRequest, (result, status) => {
            if (
              status === window.google.maps.DirectionsStatus.OK &&
              result.routes.length > 0
            ) {
              const chosenRoute = result.routes[0];
              if (whichPlace === "A") {
                setRoutePlaceA(chosenRoute.overview_path);
              } else {
                setRoutePlaceB(chosenRoute.overview_path);
              }
            } else {
              console.error("Rota oluşturulamadı:", status);
            }
          });
        }
      } catch (error) {
        console.error("Yürüme mesafesi hesaplanırken bir hata oluştu:", error);
      }
    },
    [setRoutePlaceA, setRoutePlaceB]
  );

  useEffect(() => {
    // travelMode'u sessionStorage'dan yükle
    const savedTravelMode = sessionStorage.getItem("travelMode");
    if (savedTravelMode && savedTravelMode !== "undefined") {
      setTravelMode(savedTravelMode);
    }

    const savedPlaceA = sessionStorage.getItem("placeA");
    const savedPlaceB = sessionStorage.getItem("placeB");
    if (savedPlaceA && savedPlaceA !== "undefined") {
      const parsedPlaceA = JSON.parse(savedPlaceA);
      if (parsedPlaceA.geometry && parsedPlaceA.geometry.location) {
        const location = parsedPlaceA.geometry.location;
        parsedPlaceA.geometry.location = {
          lat: () => location.lat,
          lng: () => location.lng,
        };
      }
      setPlaceA(parsedPlaceA);
      inputARef.current.value = parsedPlaceA.name;
      // travelMode'a göre hangi fonksiyonun çağrılacağı kontrol ediliyor
      if (travelMode === "walking" || travelMode === "driving") {
        findNearestStationsByWalking(parsedPlaceA, setNearestStationsA, "A");
      } else {
        findNearestStations(parsedPlaceA, setNearestStationsA);
      }
      setSearchStationA(parsedPlaceA.name);
    }

    if (savedPlaceB && savedPlaceB !== "undefined") {
      const parsedPlaceB = JSON.parse(savedPlaceB);
      if (parsedPlaceB.geometry && parsedPlaceB.geometry.location) {
        const location = parsedPlaceB.geometry.location;
        parsedPlaceB.geometry.location = {
          lat: () => location.lat,
          lng: () => location.lng,
        };
      }
      setPlaceB(parsedPlaceB);
      inputBRef.current.value = parsedPlaceB.name;
      // travelMode'a göre hangi fonksiyonun çağrılacağı kontrol ediliyor
      if (travelMode === "walking" || travelMode === "driving") {
        findNearestStationsByWalking(parsedPlaceB, setNearestStationsB, "B");
      } else {
        findNearestStations(parsedPlaceB, setNearestStationsB);
      }
      setSearchStationB(parsedPlaceB.name);
    }

    const loadAutocomplete = () => {
      autocompleteARef.current = new window.google.maps.places.Autocomplete(
        inputARef.current,
        {
          bounds: istanbulBounds,
          strictBounds: true,
          types: ["geocode", "establishment"],
        }
      );
      autocompleteARef.current.addListener("place_changed", () => {
        const selectedPlace = autocompleteARef.current.getPlace();
        setPlaceA(selectedPlace);
        sessionStorage.setItem("placeA", JSON.stringify(selectedPlace));
        // travelMode'a göre hangi fonksiyonun çağrılacağı kontrol ediliyor
        if (travelMode === "walking" || travelMode === "driving") {
          findNearestStationsByWalking(selectedPlace, setNearestStationsA, "A");
        } else {
          findNearestStations(selectedPlace, setNearestStationsA);
        }
        setSearchStationA(selectedPlace.name);
      });

      autocompleteBRef.current = new window.google.maps.places.Autocomplete(
        inputBRef.current,
        {
          bounds: istanbulBounds,
          strictBounds: true,
          types: ["geocode", "establishment"],
        }
      );
      autocompleteBRef.current.addListener("place_changed", () => {
        const selectedPlace = autocompleteBRef.current.getPlace();
        setPlaceB(selectedPlace);
        sessionStorage.setItem("placeB", JSON.stringify(selectedPlace));
        // travelMode'a göre hangi fonksiyonun çağrılacağı kontrol ediliyor
        if (travelMode === "walking" || travelMode === "driving") {
          findNearestStationsByWalking(selectedPlace, setNearestStationsB, "B");
        } else {
          findNearestStations(selectedPlace, setNearestStationsB);
        }
        setSearchStationB(selectedPlace.name);
      });
    };

    if (window.google) {
      loadAutocomplete();
    } else {
      window.initMap = () => {
        loadAutocomplete();
      };
    }
  }, [
    setPlaceA,
    setPlaceB,
    travelMode,
    findNearestStations,
    findNearestStationsByWalking,
    setTravelMode,
  ]);

  const handleRouteCreate = async () => {
    debugger;
    const metroLines = await fetchMetroLines();

    if (nearestStationsA.length > 0 && nearestStationsB.length > 0) {
      const startStation = nearestStationsA[0];
      const endStation = nearestStationsB[0];

      const { route, details, tariffs, duration } = calculateRoute(
        startStation,
        endStation,
        metroLines
      );
      setRoute(route);
      setRouteDetails(details);
      setTariffDetails(tariffs);
      setDuration(duration);
      if (travelMode === "driving") {
        changeTrafficLayer(true);
      }
      onClose();
    }
  };

  const handleClear = () => {
    setPlaceA(null);
    setPlaceB(null);
    setNearestStationsA([]);
    setNearestStationsB([]);
    sessionStorage.removeItem("placeA");
    sessionStorage.removeItem("placeB");
    sessionStorage.removeItem("travelMode");
    if (inputARef.current) inputARef.current.value = "";
    if (inputBRef.current) inputBRef.current.value = "";
    setSearchStationA("");
    setSearchStationB("");
  };

  const handleSetTravelMode = (mode) => {
    setTravelMode(mode);
    sessionStorage.setItem("travelMode", mode); // travelMode sessionda tutuluyor
  };

  return (
    <div
      className="modal-overlay-general"
      onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        backdropFilter: "blur(8px)",
      }}
    >
      <div
        className="modal-content-general"
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "90%",
          maxWidth: "600px",
          background: "linear-gradient(to bottom, #ffffff, #f8f9fa)",
          borderRadius: "25px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
          padding: "40px",
          textAlign: "center",
          animation: "fadeIn 0.5s ease-in-out",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        <h2
          style={{
            color: "#34495e",
            fontSize: "32px",
            fontWeight: "bold",
            marginBottom: "15px",
            textTransform: "uppercase",
            letterSpacing: "2px",
          }}
        >
          🚇 Rota Planlayıcı
        </h2>
        <p
          style={{
            fontSize: "18px",
            color: "#555",
            marginBottom: "30px",
            lineHeight: "1.6",
          }}
        >
          Başlangıç ve hedef noktalarınızı seçerek en yakın istasyonları bulun
          ve bir güzergah oluşturun. Seyahat modunu seçerek farklı rota
          seçeneklerini deneyin!
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginBottom: "30px",
          }}
        >
          <button
            style={{
              background: travelMode === "walking" ? "#28a745" : "#e9ecef",
              color: travelMode === "walking" ? "#fff" : "#6c757d",
              padding: "15px 25px",
              borderRadius: "12px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => {
              if (travelMode !== "walking")
                e.target.style.background = "#d4edda";
            }}
            onMouseLeave={(e) => {
              if (travelMode !== "walking")
                e.target.style.background = "#e9ecef";
            }}
            onClick={() => handleSetTravelMode("walking")}
          >
            🚶 Yürüme
          </button>
          <button
            style={{
              background: travelMode === "driving" ? "#007bff" : "#e9ecef",
              color: travelMode === "driving" ? "#fff" : "#6c757d",
              padding: "15px 25px",
              borderRadius: "12px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => {
              if (travelMode !== "driving")
                e.target.style.background = "#d0e2ff";
            }}
            onMouseLeave={(e) => {
              if (travelMode !== "driving")
                e.target.style.background = "#e9ecef";
            }}
            onClick={() => handleSetTravelMode("driving")}
          >
            🚗 Araç
          </button>
          <button
            style={{
              background: travelMode === "default" ? "#ffc107" : "#e9ecef",
              color: travelMode === "default" ? "#fff" : "#6c757d",
              padding: "15px 25px",
              borderRadius: "12px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => {
              if (travelMode !== "default")
                e.target.style.background = "#ffe8a1";
            }}
            onMouseLeave={(e) => {
              if (travelMode !== "default")
                e.target.style.background = "#e9ecef";
            }}
            onClick={() => handleSetTravelMode("default")}
          >
            🦅 Kuş Uçuşu
          </button>
        </div>

        <div className="input-container" style={{ marginBottom: "20px" }}>
          <input
            ref={inputARef}
            type="text"
            placeholder="Başlangıç noktası"
            style={{
              width: "100%",
              padding: "14px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontSize: "16px",
              boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.1)",
              outline: "none",
              marginBottom: "15px",
            }}
          />
        </div>
        <div className="input-container" style={{ marginBottom: "30px" }}>
          <input
            ref={inputBRef}
            type="text"
            placeholder="Hedef noktası"
            style={{
              width: "100%",
              padding: "14px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontSize: "16px",
              boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.1)",
              outline: "none",
            }}
          />
        </div>

        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          <button
            onClick={handleClear}
            style={{
              background: "#6c757d",
              color: "#fff",
              padding: "12px 25px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "600",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.background = "#5a6268")}
            onMouseLeave={(e) => (e.target.style.background = "#6c757d")}
          >
            Temizle
          </button>
          <button
            onClick={handleRouteCreate}
            style={{
              background: "#28a745",
              color: "#fff",
              padding: "12px 25px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "600",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.background = "#218838")}
            onMouseLeave={(e) => (e.target.style.background = "#28a745")}
          >
            Rota Oluştur
          </button>
          <button
            onClick={onClose}
            style={{
              background: "#dc3545",
              color: "#fff",
              padding: "12px 25px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "600",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => (e.target.style.background = "#c82333")}
            onMouseLeave={(e) => (e.target.style.background = "#dc3545")}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
}
